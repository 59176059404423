import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAlertContext, useUserContext } from "../contexts";
import { Clinic, ClinicSettingUpdate, WaitTimeGroup } from "../models";
import {
	AlertColor,
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useHttp } from "../hooks";
import { ClinicSetting } from "../models";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { InputNumber } from "../components";

const WaitTimeNumberGroups = "OM_WaitTimeNumberGroups";
export const ClinicSettingsPage = () => {
	const { user } = useUserContext();
	const [clinic, setClinic] = useState<Clinic | undefined>(undefined);
	const { showAlert, clearAlert } = useAlertContext();

	const clinicSettingsUrl = (clinicId: string) =>
		`${process.env.REACT_APP_CORNERSTONE_URL}clinicsetting/clinicsettings/${clinicId}`;
	const {
		data,
		isError,
		isLoading,
		fetchData: fecthClinicSettings,
	} = useHttp<ClinicSetting[]>(clinicSettingsUrl(clinic?.id.toString() || ""), {
		method: "GET",
	});

	const [omSettings, setOMSettings] = useState<ClinicSetting[] | undefined>(
		undefined,
	);
	const orignalOmSettings = useRef<ClinicSetting[] | undefined>(undefined);
	const [doctorSettings, setDoctorSettings] = useState<
		ClinicSetting[] | undefined
	>(undefined);
	const orignalDoctorSettings = useRef<ClinicSetting[] | undefined>(undefined);
	const [dataChanged, setDataChanged] = useState(false);
	const [errors, setErrors] = useState<string[] | undefined>(undefined);

	const updatedSettings = (
		omSettings: ClinicSetting[] | undefined,
		doctorSettings: ClinicSetting[] | undefined,
	) => {
		const updated = (omSettings || []).concat(doctorSettings || []);
		return updated.map((setting) => ({
			id: setting.id,
			clinicId: setting.clinicId,
			settingId: setting.settingId,
			value: setting.value,
			username: user?.username,
		}));
	};

	const { isError: isUpdateError, fetchData: UpdateClinicSettings } = useHttp<
		ClinicSettingUpdate[]
	>(`${process.env.REACT_APP_CORNERSTONE_URL}clinicsetting/clinicsetting/`, {
		method: "PUT",
		body: updatedSettings(omSettings, doctorSettings),
	});

	useEffect(() => {
		clearAlert();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (user?.clinics.length === 1) {
			setClinic(user?.clinics[0]);
		}
	}, [user]);

	useEffect(() => {
		if (clinic) {
			fecthClinicSettings();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clinic]);

	useEffect(() => {
		const excludeSettings = [
			"OM_WaitTimeCalculationMethod",
			"OM_WaitTimePercentGroups",
		];
		if (data) {
			const omSettings = data.filter(
				(setting) =>
					setting.settingName.startsWith("OM_") &&
					!excludeSettings.includes(setting.settingName),
			);
			setOMSettings(omSettings);
			orignalOmSettings.current = cloneDeep(omSettings);
			const doctorSettings = data.filter((setting) =>
				setting.settingName.startsWith("DocCheckin_"),
			);
			setDoctorSettings(doctorSettings);
			orignalDoctorSettings.current = cloneDeep(doctorSettings);
		}
	}, [data]);

	useEffect(() => {
		if (isUpdateError) {
			setAlert("Error updating clinic settings", "error");
		} else if (isError) {
			setAlert("Error fetching clinic settings", "error");
		} else {
			clearAlert();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError, isUpdateError]);

	const handleClinicChange = async (event: SelectChangeEvent) => {
		const clinicId = event.target.value;
		setClinic(user?.clinics.find((clinic) => clinic.id === Number(clinicId)));
	};

	const handleSettingChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value, checked } = event.target;

		const getSettingsAndSetter = () => {
			if (name.startsWith("OM_")) {
				return { settings: omSettings, setSettings: setOMSettings };
			} else {
				return { settings: doctorSettings, setSettings: setDoctorSettings };
			}
		};

		const updateSettingValue = (setting: any) => {
			switch (setting.dataType) {
				case "boolean":
					return (setting.value = checked ? "1" : "0");
				case "number":
					return (setting.value = value);
				default:
					return setting.value;
			}
		};

		const updateSettingsArray = (settingsArray: any[], newSetting: any) => {
			const settingIndex = settingsArray.findIndex(
				(setting) => setting.settingName === name,
			);
			const updatedSettingsArray = [...settingsArray];
			updatedSettingsArray.splice(settingIndex, 1, newSetting);
			return updatedSettingsArray;
		};

		const { settings, setSettings } = getSettingsAndSetter();
		if (!settings) return;

		const setting = settings.find((s) => s.settingName === name);
		if (!setting) return;

		updateSettingValue(setting);

		const newSettingsArray = updateSettingsArray(settings, setting);
		setSettings(newSettingsArray);
		setDataChanged(true);
	};

	const handleWaitTimeGroupChange = async (
		event: ChangeEvent<HTMLInputElement>,
	) => {
		const { name, value } = event.target;

		const names = name.split("-");

		const settingIndex = omSettings?.findIndex(
			(setting) => setting.settingName === WaitTimeNumberGroups,
		);

		if (omSettings == null || settingIndex == null || settingIndex === -1) {
			return;
		}

		const waitTimeGroups: WaitTimeGroup[] = JSON.parse(
			omSettings[settingIndex].value,
		);

		const groupIndices: Record<string, number> = {
			short: 0,
			medium: 1,
			long: 2,
		};

		if (groupIndices[names[0]] !== undefined) {
			const groupIndex = groupIndices[names[0]];
			if (names[1] === "min") {
				waitTimeGroups[groupIndex].min = Number(value);
			} else {
				waitTimeGroups[groupIndex].max = Number(value);
			}
		}

		const updatedOmSetting = {
			...omSettings[settingIndex],
			value: JSON.stringify(waitTimeGroups),
		};

		const newOmSettings = [...omSettings];
		newOmSettings.splice(settingIndex, 1, updatedOmSetting);
		setOMSettings(newOmSettings);
		setDataChanged(true);
	};

	const setAlert = (message: string, alertType: AlertColor) => {
		showAlert({
			message,
			alertType,
		});
		setTimeout(() => {
			// Have to use setTimeout to wait for alert to be rendered
			const alert = document.getElementById("alert");
			alert?.scrollIntoView({ behavior: "smooth" });
		}, 500);
	};

	const isValid = (): boolean => {
		const waitTimeNumberGroupsItem = omSettings?.find(
			(setting) => setting.settingName === WaitTimeNumberGroups,
		);
		if (!waitTimeNumberGroupsItem) {
			return false;
		}
		const waitTimeGroups: WaitTimeGroup[] = JSON.parse(
			waitTimeNumberGroupsItem.value,
		);
		let isError = false;
		const errors: string[] = [];
		waitTimeGroups.forEach((group: WaitTimeGroup, index: number) => {
			const prevGroup = index >= 1 ? waitTimeGroups[index - 1] : undefined;
			if (index === 0) {
				if (Number(group.min) > 0) {
					isError = true;
					errors.push("Range does not start from 0");
				}
			}
			if (index !== 2 && Number(group.max) < Number(group.min)) {
				isError = true;
				errors.push(
					`For ${group.name}, Max(${group.max}) is not greater than min(${group.min})`,
				);
			}
			if (prevGroup) {
				if (Number(group.min) === Number(prevGroup.max)) {
					isError = true;
					errors.push(`${group.min} appears in 2 ranges`);
				}
				if (Number(group.min) - Number(prevGroup.max) > 1) {
					isError = true;
					errors.push(`Gap in range from ${prevGroup.max} to ${group.min}`);
				}
			}
		});
		setErrors(isError ? errors : undefined);
		return !isError;
	};

	const handleSaveSettings = async (savechanges: boolean) => {
		if (!savechanges) {
			setOMSettings(cloneDeep(orignalOmSettings.current));
			setDoctorSettings(cloneDeep(orignalDoctorSettings.current));
			setDataChanged(false);
			return;
		}
		const isChanges =
			!isEqual(omSettings, orignalOmSettings.current) ||
			!isEqual(doctorSettings, orignalDoctorSettings.current);

		if (!isChanges) {
			setAlert("You have not made any changes. No saving done", "error");
			setDataChanged(false);
			return;
		}
		if (!isValid()) {
			return;
		}
		await UpdateClinicSettings();
		if (!isUpdateError) {
			setAlert("Settings updated", "success");
			setDataChanged(false);
			orignalOmSettings.current = cloneDeep(omSettings);
			orignalDoctorSettings.current = cloneDeep(doctorSettings);
		}
	};

	const renderWaitTimeGroups = (setting: ClinicSetting) => {
		const waitTimGroups: WaitTimeGroup[] = JSON.parse(setting.value);
		return (
			<Table style={{ maxWidth: 300 }} className="inner-table">
				<TableHead>
					<TableRow>
						<TableCell size="small">Label</TableCell>
						<TableCell size="small" align="left">
							Lower limit
						</TableCell>
						<TableCell size="small" align="left">
							Upper limit
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{waitTimGroups.map((group, index) => (
						<TableRow key={group.name}>
							<TableCell>{group.name}</TableCell>
							<TableCell>
								<InputNumber
									value={group.min}
									name={`${group.name}-min`}
									handleChange={handleWaitTimeGroupChange}
									min={0}
									width={100}
								/>
							</TableCell>
							<TableCell>
								{index < 2 && (
									<InputNumber
										value={group.max}
										name={`${group.name}-max`}
										handleChange={handleWaitTimeGroupChange}
										min={0}
										width={100}
									/>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	};

	const renderSetting = (setting: ClinicSetting) => {
		return (
			<>
				<TableRow
					key={setting.id}
					sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
				>
					<TableCell align="left">
						{setting.dataType === "boolean" && (
							<Switch
								checked={setting.value === "1"}
								onChange={handleSettingChange}
								name={setting.settingName}
							/>
						)}
						{setting.dataType === "number" && (
							<InputNumber
								value={setting.value}
								name={setting.settingName}
								handleChange={handleSettingChange}
								min={0}
								width={100}
							/>
						)}
						{setting.settingName === WaitTimeNumberGroups &&
							renderWaitTimeGroups(setting)}
					</TableCell>
					<TableCell align="left">
						{setting.settingDescription}
						{setting.settingName === WaitTimeNumberGroups &&
							errors &&
							errors.map((error, index) => (
								<div key={`error-${index}`} className="error">
									{error}
								</div>
							))}
					</TableCell>
				</TableRow>
			</>
		);
	};

	if (isLoading) {
		return (
			<Box className="icon-loading">
				<CircularProgress size={70} />
			</Box>
		);
	} else {
		return (
			<>
				<h2>Clinic settings</h2>
				{user?.clinics.length === 1 ? (
					<h3>{user.clinics[0].name}</h3>
				) : (
					<FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
						<InputLabel id="select-clinic-label">Clinic</InputLabel>
						<Select
							labelId="select-clinic-label"
							id="select-clinic"
							value={clinic ? (clinic as Clinic).id.toString() : ""}
							onChange={handleClinicChange}
							label="Clinic"
						>
							{user?.clinics.map((clinic) => (
								<MenuItem key={clinic.id} value={clinic.id}>
									{clinic.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				{omSettings && (
					<>
						<h4>Our Medical app settings</h4>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} className="striped-table">
								<TableBody>
									{omSettings.map((setting) => renderSetting(setting))}
								</TableBody>
							</Table>
						</TableContainer>
					</>
				)}
				{doctorSettings && (
					<>
						<h4>Doctor's check-in app settings</h4>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} className="striped-table">
								<TableBody>
									{doctorSettings.map((setting) => renderSetting(setting))}
								</TableBody>
							</Table>
						</TableContainer>
					</>
				)}
				{(omSettings || doctorSettings) && (
					<div style={{ display: "flex", gap: 20, marginTop: 20 }}>
						<Button
							variant="outlined"
							onClick={() => handleSaveSettings(false)}
							disabled={!dataChanged}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							onClick={() => handleSaveSettings(true)}
							disabled={!dataChanged}
						>
							Save
						</Button>
					</div>
				)}
			</>
		);
	}
};
